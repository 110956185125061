// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Subscriptions_section_AMlBI{width:100%;padding:12px;color:var(--supster-secondary);background:#f8f8f8}.Subscriptions_block_2sP9f{padding:15px;background:#fff;border-radius:6px;box-shadow:0 2px 5px rgba(var(--supster-secondary),.08)}", ""]);
// Exports
exports.locals = {
	"section": "Subscriptions_section_AMlBI",
	"block": "Subscriptions_block_2sP9f"
};
module.exports = exports;
