<template>
  <v-button size="lg" :color="buttonColor" :class="classes" @click="onRenew">
    {{ label }}
  </v-button>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VButton from '@elements/VButton.vue'
import { isActive } from '@views/subscriptions/services/supscription'
import PromotionalApi from '@services/promotional'

export default Vue.extend({
  name: 'SubscriptionContentRenewButton',
  components: { VButton },
  props: {
    subscription: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
    isActive() {
      return isActive({
        status: this.subscription.status,
        statusRecurrent: this.subscription.status_recurrent,
      })
    },
    amount() {
      return this.subscription.amount ?? 0
    },
    period() {
      return this.subscription.period ?? 0
    },
    activeLabel() {
      return `${this.appCurrencySymbol()}${this.amount} /
      ${this.$t(`subscription.${this.period}`)}`
    },
    inactiveLabel() {
      return `${this.$t('renewFor')} ${this.activeLabel}`
    },
    label() {
      return this.isActive ? this.activeLabel : this.inactiveLabel
    },
    buttonColor() {
      return this.isActive ? 'transparent-light' : 'primary'
    },
    classes() {
      return this.isActive && this.$style.button
    },
    promotionalId() {
      return this.subscription.tariff?.promotional_page?.id
    },
  },
  methods: {
    onRenew() {
      if (this.isActive) {
        return
      }
      this.goToPromotionalPage()
    },
    async goToPromotionalPage() {
      if (this.promotionalId) {
        await this.$router.push({
          name: 'Promotional',
          params: { id: this.promotionalId, locale: this.locale },
          query: { chat_id: this.chat?.id, gpt: true },
        })
      }
    },
  },
})
</script>

<style module lang="scss">
.button {
  border: 1px solid $secondary !important;
}
</style>
