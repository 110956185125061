// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Subscription_section_3kVD6{box-shadow:0 2px 5px rgba(0,0,0,.08)}", ""]);
// Exports
exports.locals = {
	"section": "Subscription_section_3kVD6"
};
module.exports = exports;
