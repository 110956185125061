<template>
  <div :class="$style.container">
    <div :class="$style.question">{{ question }}</div>
    <textarea
      ref="input"
      v-model="text"
      :class="$style.input"
      name="text"
      wrap="hard"
      type="text"
      :placeholder="$t(`subscriptionCancellation.placeholder`)"
      maxlength="600"
    />
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SubscriptionCancelModalQuestionnaire',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    questionType: {
      type: String,
      required: true,
      validator: (value) => ['reason', 'favorites'].includes(value),
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    question() {
      return this.$t(`subscriptionCancellation.questions.${this.questionType}`)
    },
    text: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.$refs.input.focus()
  },
  updated() {
    this.$refs.input.focus()
  },
})
</script>
<style lang="scss" module>
.container {
  .question {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 108px;
    padding: 10px 12px;
    overflow-x: hidden;
    overflow-y: auto;
    color: $secondary;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    word-wrap: break-word;
    border: 1px solid $secondary-light;
    border-radius: 6px;
    outline: none;
    resize: none;
    margin: 21px 0;

    @include scrollbar();
  }
}
</style>
