// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionCancelModalSkeleton_item_2nbbf{background:#f2f2f2;margin-bottom:21px;background:linear-gradient(90deg,hsla(0,0%,51%,.2) 8%,hsla(0,0%,51%,.3) 18%,hsla(0,0%,51%,.2) 33%);background-size:800px 100px;animation:SubscriptionCancelModalSkeleton_wave-lines_HBNYe 1s ease-out infinite;border-radius:6px}.SubscriptionCancelModalSkeleton_header_3sbQC{width:100%;height:40px}.SubscriptionCancelModalSkeleton_content_3GIfD{width:100%;height:300px}@keyframes SubscriptionCancelModalSkeleton_wave-lines_HBNYe{0%{background-position:-468px 0}to{background-position:468px 0}}", ""]);
// Exports
exports.locals = {
	"item": "SubscriptionCancelModalSkeleton_item_2nbbf",
	"wave-lines": "SubscriptionCancelModalSkeleton_wave-lines_HBNYe",
	"header": "SubscriptionCancelModalSkeleton_header_3sbQC",
	"content": "SubscriptionCancelModalSkeleton_content_3GIfD"
};
module.exports = exports;
