// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionHeader_section_3aVlK{display:grid;grid-template-columns:1fr auto;width:100%;-moz-column-gap:10px;column-gap:10px}.SubscriptionHeader_content_34ECH{display:grid;align-self:center;row-gap:9px}.SubscriptionHeader_header_3l9jW{display:grid;grid-auto-columns:min-content;grid-auto-flow:column;align-items:center;-moz-column-gap:6px;column-gap:6px}.SubscriptionHeader_header_3l9jW .SubscriptionHeader_status_3G-bj{padding:0 6px;color:#fff;font-weight:500;font-size:12px;line-height:18px;background:#a09f9f;border-radius:6px}.SubscriptionHeader_header_3l9jW .SubscriptionHeader_status_3G-bj.SubscriptionHeader_active_d8sTQ{color:var(--supster-primary-light-text-color);background:var(--supster-primary-light-background)}.SubscriptionHeader_header_3l9jW .SubscriptionHeader_status_3G-bj.SubscriptionHeader_rejected_NLPeN{background:#fb4e4e}.SubscriptionHeader_header_3l9jW .SubscriptionHeader_app_19Vak{font-weight:400;font-size:12px;line-height:12px;white-space:nowrap}.SubscriptionHeader_name_3KIGP{font-weight:500;font-size:18px;line-height:17px}.SubscriptionHeader_number_38KgX{align-self:center;font-size:36px;line-height:32px;opacity:.1}", ""]);
// Exports
exports.locals = {
	"section": "SubscriptionHeader_section_3aVlK",
	"content": "SubscriptionHeader_content_34ECH",
	"header": "SubscriptionHeader_header_3l9jW",
	"status": "SubscriptionHeader_status_3G-bj",
	"active": "SubscriptionHeader_active_d8sTQ",
	"rejected": "SubscriptionHeader_rejected_NLPeN",
	"app": "SubscriptionHeader_app_19Vak",
	"name": "SubscriptionHeader_name_3KIGP",
	"number": "SubscriptionHeader_number_38KgX"
};
module.exports = exports;
