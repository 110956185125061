<template>
  <div :class="$style.section">
    <VGrid
      class-name="subscriptions"
      no-data
      :length="subscriptionsLength"
      @infinite="onGetSubscriptions"
    >
      <Subscription
        v-for="(subscription, index) in subscriptions"
        :key="subscription.id"
        :class="$style.block"
        :subscription="subscription"
        :index="Number(index)"
        @cancel-subscription="onCancelSubscription"
      />
    </VGrid>
    <SubscriptionCancelModal />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VGrid from '@layouts/VGrid.vue'
import Config from '@config/index'
import SubscriptionCancelModal from '@views/subscriptions/list/SubscriptionCancelModal.vue'
import Subscription from './list/Subscription.vue'

export default Vue.extend({
  name: 'Subscriptions',
  components: { SubscriptionCancelModal, VGrid, Subscription },
  data() {
    return {
      cacheHandlersMapId: null,
    }
  },
  computed: {
    ...mapGetters('Subscriptions', ['subscriptions', 'subscriptionsLength']),
    ...mapGetters('Page', ['pageIsFooter']),
  },
  methods: {
    ...mapActions('Subscriptions', ['getSubscriptions']),
    ...mapMutations('Page', ['setPageTitle', 'setPageBack', 'setPageIsFooter']),
    ...mapMutations('Subscriptions', [
      'setSubscriptions',
      'resetSubscriptions',
      'setCancelingSubscriptionId',
    ]),
    async onGetSubscriptions(scroll) {
      await this.getSubscriptions({ scroll })
    },
    onCancelSubscription(subscriptionId) {
      // eslint-disable-next-line no-undef
      ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'cancel-subscription-begin')
      this.setCancelingSubscriptionId(subscriptionId)
    },
  },
  mounted() {
    this.cacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.SUBSCRIPTIONS,
      cacheHandlers: [(data) => this.setSubscriptions(data)],
    })
    this.setPageTitle({ name: 'mySubscriptions' })
    if (!this.pageIsFooter) {
      this.setPageIsFooter(true)
    }
  },
  beforeDestroy() {
    this.resetSubscriptions()
    if (this.cacheHandlersMapId) {
      this.$cacheManager.remove([this.cacheHandlersMapId])
    }
  },
})
</script>
<style lang="scss" module>
.section {
  width: 100%;
  padding: 12px;
  color: $secondary;
  background: $shadow;
}

.block {
  padding: 15px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba($secondary, 0.08);
}
</style>
