// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionCancelModalSuccess_container_2taPS{color:var(--supster-secondary);font-size:12px;font-weight:400;line-height:18px}.SubscriptionCancelModalSuccess_container_2taPS .SubscriptionCancelModalSuccess_header_29N7b{margin-bottom:21px;font-size:18px;font-weight:500;line-height:24px}.SubscriptionCancelModalSuccess_container_2taPS .SubscriptionCancelModalSuccess_paragraph_2zOcr{margin-bottom:21px}", ""]);
// Exports
exports.locals = {
	"container": "SubscriptionCancelModalSuccess_container_2taPS",
	"header": "SubscriptionCancelModalSuccess_header_29N7b",
	"paragraph": "SubscriptionCancelModalSuccess_paragraph_2zOcr"
};
module.exports = exports;
