// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionCancelModal_modal_Qj_rP{gap:0;padding:36px}.SubscriptionCancelModal_actions_XEQKD{gap:12px}.SubscriptionCancelModal_actions_XEQKD,.SubscriptionCancelModal_button_3GLzs{display:flex}.SubscriptionCancelModal_button_3GLzs .SubscriptionCancelModal_buttonLabel_lstl-{display:flex;gap:10px;align-items:center;justify-content:space-between;text-align:initial}.SubscriptionCancelModal_button_3GLzs .SubscriptionCancelModal_buttonLabel_lstl- svg{flex-grow:0;flex-shrink:0;fill:var(--supster-primary-light-background)}", ""]);
// Exports
exports.locals = {
	"modal": "SubscriptionCancelModal_modal_Qj_rP",
	"actions": "SubscriptionCancelModal_actions_XEQKD",
	"button": "SubscriptionCancelModal_button_3GLzs",
	"buttonLabel": "SubscriptionCancelModal_buttonLabel_lstl-"
};
module.exports = exports;
