// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionContent_section_1mxlf{display:grid;margin-top:5px;row-gap:15px}.SubscriptionContent_separator_3Lmfe{height:1.5px;background-color:var(--supster-secondary);opacity:.1}.SubscriptionContent_dates_1aCg3{display:grid;grid-template-columns:repeat(3,1fr)}.SubscriptionContent_date_qucJb{display:grid;row-gap:6px}.SubscriptionContent_date_qucJb .SubscriptionContent_name_3ONnx{opacity:.4}.SubscriptionContent_date_qucJb .SubscriptionContent_name_3ONnx,.SubscriptionContent_date_qucJb .SubscriptionContent_value_3jg1L{font-weight:400;font-size:12px;line-height:12px}.SubscriptionContent_date_qucJb .SubscriptionContent_value_3jg1L{display:flex;align-items:flex-end}.SubscriptionContent_buttons_33RlR{display:grid;grid-template-columns:1fr auto;-moz-column-gap:15px;column-gap:15px}.SubscriptionContent_nogap_23ld5{-moz-column-gap:0;column-gap:0}.SubscriptionContent_button_1mYMS{border:1px solid var(--supster-secondary)!important}", ""]);
// Exports
exports.locals = {
	"section": "SubscriptionContent_section_1mxlf",
	"separator": "SubscriptionContent_separator_3Lmfe",
	"dates": "SubscriptionContent_dates_1aCg3",
	"date": "SubscriptionContent_date_qucJb",
	"name": "SubscriptionContent_name_3ONnx",
	"value": "SubscriptionContent_value_3jg1L",
	"buttons": "SubscriptionContent_buttons_33RlR",
	"nogap": "SubscriptionContent_nogap_23ld5",
	"button": "SubscriptionContent_button_1mYMS"
};
module.exports = exports;
