<template>
  <div :class="$style.section">
    <div :class="$style.separator"></div>
    <div :class="$style.dates">
      <div :class="$style.date">
        <span :class="$style.name">{{ $t('startedOn') }}:</span>
        <v-date-gap :class="$style.value" type="MMM D YYYY" :date="subscription.created" />
      </div>
      <div :class="$style.date">
        <span :class="$style.name">{{ $t('lastPayment') }}:</span>
        <v-date-gap
          v-if="subscription.updated"
          :class="$style.value"
          type="MMM D YYYY"
          :date="subscription.updated"
        />
        <span v-else :class="$style.value">—/—</span>
      </div>
      <div :class="$style.date">
        <span :class="$style.name">{{ $t('nextPayment') }}:</span>
        <v-date-gap
          v-if="subscription.next_payment_date && !isActiveSubCanceled"
          :class="$style.value"
          type="MMM D YYYY"
          :date="subscription.next_payment_date"
        />
        <span v-else>-</span>
      </div>
    </div>
    <div :class="[$style.buttons, !isSubActive && isActiveSubCanceled ? $style.nogap : '']">
      <SubscriptionContentRenewButton :subscription="subscription" />
      <v-button
        v-if="isSubActive && !isActiveSubCanceled"
        size="lg"
        @click="$emit('cancel', subscription)"
      >
        {{ $t('cancel') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDateGap from '@layouts/VDateGap.vue'
import SubscriptionContentRenewButton from '@views/subscriptions/list/SubscriptionContentRenewButton.vue'

export default Vue.extend({
  name: 'SubscriptionContent',
  components: { SubscriptionContentRenewButton, VButton, VDateGap },
  props: {
    subscription: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
    isSubActive() {
      return this.subscription.status === 1
    },
    isActiveSubCanceled() {
      return this.subscription.renew_mode === 0
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  margin-top: 5px;
  row-gap: 15px;
}

.separator {
  height: 1.5px;
  background-color: $secondary;
  opacity: 0.1;
}

.dates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.date {
  display: grid;
  row-gap: 6px;

  & .name {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.4;
  }

  & .value {
    display: flex;
    align-items: flex-end;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }
}

.buttons {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 15px;
}

.nogap {
  column-gap: 0;
}

.button {
  border: 1px solid $secondary !important;
}
</style>
