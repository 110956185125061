<template>
  <transition name="slide-up" mode="out-in">
    <div :class="$style.section">
      <div :class="$style.content">
        <div :class="$style.header">
          <div
            :class="[$style.status, isSubActive && $style.active, isRejected && $style.rejected]"
          >
            {{ $t(`subscription.${subscriptionStatus}`) }}
          </div>
          <p :class="$style.app">
            {{ subscription.app.name }}
          </p>
        </div>
        <h2 :class="$style.name">{{ subscription.tariff.promotional_page.name || '-' }}</h2>
      </div>
      <h1 :class="$style.number">{{ number }}</h1>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SubscriptionHeader',
  components: {},
  props: {
    subscription: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  computed: {
    number() {
      return this.index + 1 >= 10 ? this.index + 1 : `0${this.index + 1}`
    },
    isSubActive() {
      return (
        this.subscription.status === 1 &&
        this.subscription.status_recurrent === 1 &&
        this.subscription.renew_mode === 1
      )
    },
    isRejected() {
      return this.subscription.status === 1 && this.subscription.status_recurrent === 2
    },
    isCanceled() {
      return this.subscription.status === 3 || this.subscription.renew_mode === 0
    },
    subscriptionStatus() {
      return this.isCanceled ? 'cancelled' : this.isRejected ? 'rejected' : 'active'
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  column-gap: 10px;
}

.content {
  display: grid;
  align-self: center;
  row-gap: 9px;
}

.header {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 6px;

  & .status {
    padding: 0 6px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    background: $secondary-medium;
    border-radius: 6px;

    &.active {
      color: $primary-light-text-color;
      background: $primary-light-background;
    }

    &.rejected {
      background: $error;
    }
  }

  & .app {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
  }
}

.name {
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
}

.number {
  align-self: center;
  font-size: 36px;
  line-height: 32px;
  opacity: 0.1;
}
</style>
