<template>
  <content-expansion
    :class="$style.section"
    size="xl"
    icon-size="lg"
    :name="`subscriptions-${subscription.id}`"
  >
    <template #name>
      <subscription-header :subscription="subscription" :index="index" />
    </template>
    <subscription-content
      :subscription="subscription"
      @cancel="$emit('cancel-subscription', subscription.id)"
    />
  </content-expansion>
</template>

<script>
import Vue from 'vue'
import ContentExpansion from '@layouts/expansions/list/ContentExpansion.vue'
import SubscriptionHeader from './SubscriptionHeader.vue'
import SubscriptionContent from './SubscriptionContent.vue'

export default Vue.extend({
  name: 'Subscription',
  components: { ContentExpansion, SubscriptionContent, SubscriptionHeader },
  props: {
    subscription: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
})
</script>

<style lang="scss" module>
.section {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}
</style>
