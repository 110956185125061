<template>
  <div :class="$style.container">
    <div :class="$style.header">
      {{ header }}
    </div>
    <div v-if="isFreeTrialAllowed" :class="[$style.paragraph, $style.underline]">
      {{ $t('subscriptionCancellation.description1') }}
    </div>
    <i18n :path="announcementPath" :class="[$style.paragraph, $style.announcement]" tag="div">
      <template #limit>
        <span :class="$style.announcementLimit">
          {{ $t('subscriptionCancellation.announcementLimit') }}
        </span>
      </template>
    </i18n>
    <div v-if="isFreeTrialAllowed" :class="[$style.paragraph, $style.overline]">
      {{ $t('subscriptionCancellation.description2') }}
    </div>
    <div v-if="isFreeTrialAllowed" :class="$style.addition">
      {{ $t('subscriptionCancellation.addition') }}
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SubscriptionCancelModalMonthTrial',
  computed: {
    ...mapGetters('Subscriptions', ['cancelingSubscription']),
    isFreeTrialAllowed() {
      return !!this.cancelingSubscription?.free_renew_allowed
    },
    header() {
      return this.isFreeTrialAllowed
        ? this.$t('subscriptionCancellation.dontRush')
        : this.$t('subscriptionCancellation.areYouSure')
    },
    announcementPath() {
      return this.isFreeTrialAllowed
        ? 'subscriptionCancellation.announcement'
        : 'subscriptionCancellation.announcement2'
    },
  },
})
</script>

<style lang="scss" module>
.container {
  color: $secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 21px;

  .header {
    margin-bottom: 9px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .paragraph {
    padding: 12px 0;
  }
  .underline {
    border-bottom: 1px solid $primary-light-background;
  }
  .overline {
    border-top: 1px solid $primary-light-background;
  }
  .announcement {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &Limit {
      color: $primary-light-background;
      text-transform: uppercase;
    }
  }
  .addition {
    color: $grey-dark;
  }
}
</style>
