// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionCancelModalMonthTrial_container_3aDZm{color:var(--supster-secondary);font-size:12px;font-weight:400;line-height:18px;margin-bottom:21px}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_header_3K1Wy{margin-bottom:9px;font-size:18px;font-weight:500;line-height:24px}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_paragraph_2Lwgc{padding:12px 0}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_underline_2UISe{border-bottom:1px solid var(--supster-primary-light-background)}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_overline_3DYIy{border-top:1px solid var(--supster-primary-light-background)}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_announcement_WojTS{font-size:14px;font-weight:500;line-height:20px}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_announcementLimit_331gn{color:var(--supster-primary-light-background);text-transform:uppercase}.SubscriptionCancelModalMonthTrial_container_3aDZm .SubscriptionCancelModalMonthTrial_addition_9-33F{color:#737373}", ""]);
// Exports
exports.locals = {
	"container": "SubscriptionCancelModalMonthTrial_container_3aDZm",
	"header": "SubscriptionCancelModalMonthTrial_header_3K1Wy",
	"paragraph": "SubscriptionCancelModalMonthTrial_paragraph_2Lwgc",
	"underline": "SubscriptionCancelModalMonthTrial_underline_2UISe",
	"overline": "SubscriptionCancelModalMonthTrial_overline_3DYIy",
	"announcement": "SubscriptionCancelModalMonthTrial_announcement_WojTS",
	"announcementLimit": "SubscriptionCancelModalMonthTrial_announcementLimit_331gn",
	"addition": "SubscriptionCancelModalMonthTrial_addition_9-33F"
};
module.exports = exports;
