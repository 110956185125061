<template>
  <div :class="$style.container">
    <div :class="[$style.item, $style.header]" />
    <div :class="[$style.item, $style.content]" />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SubscriptionCancelModalSkeleton',
})
</script>

<style lang="scss" module>
.item {
  background: $grey;
  margin-bottom: 21px;
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-lines 1s infinite ease-out;
  border-radius: 6px;
}
.header {
  width: 100%;
  height: 40px;
}
.content {
  width: 100%;
  height: 300px;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
