<template>
  <div :class="$style.container">
    <div :class="$style.header">
      {{ $t('subscriptionCancellation.cancelling') }}
    </div>
    <div :class="$style.paragraph">
      {{ $t('subscriptionCancellation.goToEmail') }}
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'SubscriptionCancelModalSuccess',
})
</script>
<style lang="scss" module>
.container {
  color: $secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  .header {
    margin-bottom: 21px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .paragraph {
    margin-bottom: 21px;
  }
}
</style>
