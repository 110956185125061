// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_question_qZM1R{font-size:18px;font-weight:500;line-height:24px}.SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_input_fAQS3{display:block;box-sizing:border-box;width:100%;height:108px;padding:10px 12px;overflow-x:hidden;overflow-y:auto;color:var(--supster-secondary);font-weight:400;font-size:15px;line-height:22px;word-wrap:break-word;border:1px solid #e5e3e3;border-radius:6px;outline:none;resize:none;margin:21px 0}.SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_input_fAQS3::-webkit-scrollbar{width:5px;background:transparent}.SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_input_fAQS3::-webkit-scrollbar-thumb{background:#181a22;border-radius:8px;cursor:pointer}.SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_input_fAQS3::-webkit-scrollbar-thumb:hover{background:#24272f}.SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_input_fAQS3::-webkit-scrollbar-track{background:hsla(0,0%,100%,.1)}.SubscriptionCancelModalQuestionnaire_container_1dwl3 .SubscriptionCancelModalQuestionnaire_input_fAQS3::-webkit-scrollbar-corner{display:none}", ""]);
// Exports
exports.locals = {
	"container": "SubscriptionCancelModalQuestionnaire_container_1dwl3",
	"question": "SubscriptionCancelModalQuestionnaire_question_qZM1R",
	"input": "SubscriptionCancelModalQuestionnaire_input_fAQS3"
};
module.exports = exports;
