<template>
  <VModal :is-visible="isVisible" :class-name="$style.modal" @update:is-visible="isVisible = false">
    <component :is="page" v-model="currentText" :questionType="questionType" />
    <div :class="$style.actions">
      <VButton size="lg" :class="$style.button" color="border-primary" @click.stop="onLeftButton">
        <div :class="step === 0 && $style.buttonLabel">
          {{ leftButtonLabel }} <icon v-if="step === 0" name="arrow-right" width="6" height="12" />
        </div>
      </VButton>
      <VButton
        v-if="step !== 3"
        size="lg"
        @click.stop="onRightButton"
        color="primary"
        :is-loading="isLoading"
      >
        {{ rightButtonLabel }}
      </VButton>
    </div>
  </VModal>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VModal from '@modals/VModal.vue'
import VButton from '@elements/VButton.vue'
import SubscriptionsApi from '@services/subscription'
import { ToastTypes } from '@modules/toasts/constants'
import SubscriptionCancelModalSkeleton from './SubscriptionCancelModalSkeleton.vue'
import SubscriptionCancelModalMonthTrial from './SubscriptionCancelModalMonthTrial.vue'
import SubscriptionCancelModalQuestionnaire from './SubscriptionCancelModalQuestionnaire.vue'
import SubscriptionCancelModalSuccess from './SubscriptionCancelModalSuccess.vue'

export default Vue.extend({
  name: 'SubscriptionCancelModal',
  components: {
    VButton,
    VModal,
    SubscriptionCancelModalSkeleton,
    SubscriptionCancelModalMonthTrial,
    SubscriptionCancelModalQuestionnaire,
    SubscriptionCancelModalSuccess,
  },
  data() {
    return {
      step: 0,
      isLoading: false,
      reason: '',
      favorites: '',
    }
  },
  computed: {
    ...mapGetters('Subscriptions', ['cancelingSubscriptionId', 'cancelingSubscription']),
    ...mapGetters('App', ['appCurrencySymbol']),
    isFreeTrialAllowed() {
      return !!this.cancelingSubscription?.free_renew_allowed
    },
    pages() {
      return [
        'SubscriptionCancelModalMonthTrial',
        'SubscriptionCancelModalQuestionnaire',
        'SubscriptionCancelModalQuestionnaire',
        'SubscriptionCancelModalSuccess',
      ]
    },
    page() {
      return this.pages[this.step] ?? null
    },
    isVisible: {
      get() {
        return !!this.cancelingSubscriptionId
      },
      set(value) {
        if (!value) {
          this.close()
        }
      },
    },
    leftButtonLabel() {
      return this.step === 0
        ? this.$t('continueChanges')
        : this.step === 3
        ? this.$t('close')
        : this.$t('toCancel')
    },
    rightButtonLabel() {
      return this.step === 0 ? this.$t('confirm.accept') : this.$t('continue')
    },
    questionType() {
      return this.step === 1 ? 'reason' : 'favorites'
    },
    currentText: {
      get() {
        return this.step === 1 ? this.reason : this.step === 2 ? this.favorites : null
      },
      set(text) {
        if (this.step === 1) {
          this.reason = text
        }
        if (this.step === 2) {
          this.favorites = text
        }
      },
    },
  },
  methods: {
    ...mapActions('Subscriptions', ['postSubscriptionCancel', 'postSubscriptionMonthTrial']),
    ...mapMutations('Subscriptions', ['setCancelingSubscriptionId']),
    ...mapMutations('Toasts', ['pushToast']),
    onLeftButton() {
      if (this.step === 0) {
        this.next()
        return
      }
      this.close()
    },
    onRightButton() {
      if (this.step === 0) {
        if (this.isFreeTrialAllowed) {
          this.onAcceptFreeTrial()
        } else {
          this.close()
        }
        return
      }
      if (this.step === 2) {
        // eslint-disable-next-line no-undef
        ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'cancel-subscription-send-letter')
        this.sendCancellationToEmail()
        return
      }
      this.next()
    },
    next() {
      this.step = this.step > 2 ? 3 : this.step + 1
    },
    async onAcceptFreeTrial() {
      this.isLoading = true
      try {
        await this.postSubscriptionMonthTrial(this.cancelingSubscriptionId)
        this.close()
      } catch (error) {
        this.pushToast({
          type: ToastTypes.ERROR,
          message: this.$t('errors.message'),
        })
      } finally {
        this.isLoading = false
      }
    },
    async sendCancellationToEmail() {
      this.isLoading = true
      try {
        // await SubscriptionsApi.postSubscriptionCancellationToEmail(this.cancelingSubscriptionId)
        await SubscriptionsApi.updateSubscription(this.cancelingSubscriptionId, {
          renew_mode: 0,
        })
        window.location.href = this.$route.path
      } catch (error) {
        this.pushToast({
          type: ToastTypes.ERROR,
          message: this.$t('errors.message'),
        })
      } finally {
        this.isLoading = false
      }
    },
    close() {
      this.setCancelingSubscriptionId(null)
      if (this.appCurrencySymbol() === '$') {
        this.step = 1
      } else {
        this.step = 0
      }

      this.isLoading = false
      this.reason = ''
      this.favorites = ''
    },
  },
  mounted() {
    if (this.appCurrencySymbol() === '$') {
      this.step = 1
    }
  },
})
</script>
<style lang="scss" module>
.modal {
  gap: 0;
  padding: 36px;
}

.actions {
  display: flex;
  gap: 12px;
}

.button {
  display: flex;
  .buttonLabel {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    text-align: initial;

    svg {
      flex-grow: 0;
      flex-shrink: 0;
      fill: $primary-light-background;
    }
  }
}
</style>
